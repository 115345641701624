//////////////////////////////
// Actionbar
//////////////////////////////

.actionbar {
    margin-bottom: 20px;
    background-color: $actionbar-bg;
}

.actionbar.actionbar-titlebar {
    margin-top: -20px;
}

////////// Navigation //////////

.nav-actionbar {
    > li {
        float: left;

        > a + li {
            margin-left: 2px;
        }

        > a {
            padding: $nav-actionbar-link-padding;
        }

        > a,
        > a:hover,
        > a:focus {
            color: $nav-actionbar-link-color;
        }

        > a {
            &:hover,
            &:focus {
                background-color: $nav-actionbar-link-hover-bg;
            }
        }

        &.active > a {
            &,
            &:hover,
            &:focus {
                color: $nav-actionbar-active-link-hover-color;
                background-color: $nav-actionbar-active-link-hover-bg;
            }
        }
    }
}

////////// HR //////////

.actionbar hr {
    margin: 15px 0;
}

////////// Filter //////////

.actionbar .input-filter {
    margin: 10px 0 11px;
    width: 90%;
    padding-right: 10px;
    float: left !important;
}

////////// Refresh //////////

.actionbar .btn-refresh {
    margin: 7px 0 0;
    float: right !important;
}
