//////////////////////////////
// Panel tiles
//////////////////////////////

.panel-tile-link {
    &,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.panel-tile {
    color: $text-color;
    transition: 0.15s all ease;

    > .panel-heading,
    > .panel-body,
    > .panel-image-top,
    > .panel-image-bottom {
        transition: 0.15s all ease;
    }

    > .panel-body {
        height: $tile-height;
    }
}

.panel-tile:hover,
.panel-tile.active {
    margin-top: -$tile-hover-padding;
    margin-left: -$tile-hover-padding;
    margin-right: -$tile-hover-padding;
    margin-bottom: ($line-height-computed - $tile-hover-padding);

    box-shadow: 0 6px 30px rgba(0,0,0,0.2);

    > .panel-heading,
    > .panel-body {
        padding-left: ($panel-body-padding + $tile-hover-padding);
        padding-right: ($panel-body-padding + $tile-hover-padding);
    }

    > div:first-child {
        &.panel-heading {
            padding-top: ($panel-heading-v-padding + $tile-hover-padding);
        }
    }

    > div:last-child {
        &.panel-heading {
            padding-bottom: ($panel-heading-v-padding + $tile-hover-padding);
        }
    }

    // Panel image
    > div:first-child {
        &.panel-image-top {
            max-height: $panel-image-height + $tile-hover-padding;
            height: $panel-image-height + $tile-hover-padding;

            &.panel-image-sm {
                max-height: $panel-image-height-sm + $tile-hover-padding;
                height: $panel-image-height-sm + $tile-hover-padding;
            }
        }
    }

    > div:last-child {
        &.panel-image-bottom {
            max-height: $panel-image-height + $tile-hover-padding;
            height: $panel-image-height + $tile-hover-padding;

            &.panel-image-sm {
                max-height: $panel-image-height-sm + $tile-hover-padding;
                height: $panel-image-height-sm + $tile-hover-padding;
            }
        }
    }

    // Panel body
    > div:first-child {
        &.panel-body {
            height: $tile-height + $tile-hover-padding;
            padding-top: ($panel-body-padding + $tile-hover-padding);
        }
    }

    > div:last-child {
        &.panel-body {
            height: $tile-height + $tile-hover-padding;
            padding-bottom: ($panel-body-padding + $tile-hover-padding);
        }
    }

    > div:first-child:last-child {
        &.panel-body {
            height: $tile-height + ($tile-hover-padding * 2);
            padding-top: ($panel-body-padding + $tile-hover-padding);
            padding-bottom: ($panel-body-padding + $tile-hover-padding);
        }
    }
}
