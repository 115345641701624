////////////////////////////////////////
// Progress radial
////////////////////////////////////////

.progress-radial {
    width: $progress-radial-circle-size; height: $progress-radial-circle-size;

    background-color: $progress-radial-circle-background;
    border-radius: 50%;

    .circle {
        .mask,
        .fill {
            width: $progress-radial-circle-size; height: $progress-radial-circle-size;
            position: absolute;
            border-radius: 50%;

            // -webkit-backface-visibility: hidden;
            // transition: transform $progress-radial-transition-length;
            border-radius: 50%;
        }

        .mask {
            clip: rect(0px, $progress-radial-circle-size, $progress-radial-circle-size, $progress-radial-circle-size/2);
            .fill {
                clip: rect(0px, $progress-radial-circle-size/2, $progress-radial-circle-size, 0px);
                background-color: $progress-radial-circle-color;
            }
        }
    }

    .inset {
        width: $progress-radial-inset-size; height: $progress-radial-inset-size;
        margin-left: ($progress-radial-circle-size - $progress-radial-inset-size)/2;
        margin-top: ($progress-radial-circle-size - $progress-radial-inset-size)/2;

        background-color: $progress-radial-inset-color;

        position:    absolute;
        border-radius: 50%;
    }

    .circle {
        .mask.full, .fill {
            animation: progressRadial $progress-radial-transition-length normal forwards ease-in-out;
        }
    }
}

@keyframes progressRadial {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(180deg);
    }
}
