//////////////////////////////
// Panel toggle
//////////////////////////////

.panel-toggle {
    padding-right: 40px;
    position: relative;
}

.panel-toggle {
    padding-right: 40px;
    position: relative;
}

.panel-toggle .droppanel {
    position: absolute;
    top: 10px; right: 0;
}

@media (min-width: 992px) {
    .panel-toggle .droppanel-open .dropdown-menu {
        display: block;
    }
}
