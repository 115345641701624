//////////////////////////////
// Grid
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/mixins/grid-framework";

@mixin make-grid-columns-adjustable($gutter-width, $i: 1, $list: ".col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}") {
  @for $i from (1 + 1) through $grid-columns {
    $list: "#{$list}, .col-xs-#{$i}, .col-sm-#{$i}, .col-md-#{$i}, .col-lg-#{$i}";
  }
  #{$list} {
    padding-left:  ceil(($gutter-width / 2));
    padding-right: floor(($gutter-width / 2));
  }
}
