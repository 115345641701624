//////////////////////////////
// Panel images
//////////////////////////////

.panel-image-top,
.panel-image-bottom {
    max-height: $panel-image-height;
    height: $panel-image-height;
    overflow: hidden;
    position: relative;

    img.scale {
        width: auto; height: 100%;
        margin: auto;

        min-width: 1px; min-height: 1px;
        max-width: none; max-height: none;

        position: absolute;
        top: -9999px; bottom: -9999px;
        left: -9999px; right: -9999px;
    }
}

.panel-image-top {
    border-radius: $panel-border-radius $panel-border-radius 0 0;
}

.panel-image-bottom {
    border-radius: 0 0 $panel-border-radius $panel-border-radius;
}

.panel-image-sm {
    max-height: $panel-image-height-sm;
    height: $panel-image-height-sm;
}
