.search {
    position: relative;
}

.search .form-control {
    font-size: $font-size-base;

    border: 1px solid transparent;
    border-radius: $input-border-radius;
    box-shadow: 0 1px 5px -1px rgba(0,0,0,0.4);

    &:focus {
        border-color: $input-border-focus;
    }
}

.search .btn-group {
    margin-top: -15px;

    position: absolute;
    top: 50%; right: 10px;

    .btn {
        line-height: 16px;
    }
}
