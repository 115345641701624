
.confirmation-form {
    padding-bottom: $confirmation-height;
}

.confirmation-bar {
    min-height: $confirmation-height;
    padding: 20px 0;
    background-color: $confirmation-bg;

    box-shadow: 0 -15px 20px -10px rgba(0,0,0,0.15);

    position: fixed;
    bottom: 0;
    left: 0; right: 0;

    @media (min-width: $screen-lg-min) {
        left: $sidebar-width;
        width: calc(100% - $sidebar-width);
    }

    z-index: $zindex-navbar;

    .btn {
        padding: 9px 20px;
        padding-bottom: 10px;
    }
}

@media (min-width: $grid-float-breakpoint) {
    .content-sidebar-left .confirmation-bar {
        left: $sidebar-width;
    }

    .content-sidebar-right .confirmation-bar {
        right: $sidebar-width;
    }
}
