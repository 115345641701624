//////////////////////////////
// Tables
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/tables";

th {
    font-weight: 500;
}

.table {
    > thead > tr > th {
        border-bottom-width: 1px;

        font-weight: normal;
        font-size: $font-size-small;
        line-height: 30px;
        color: $grey-500;
    }
}

.table {
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > th,
            > td {
                vertical-align: middle;
            }
        }
    }
}

.panel > .ng-scope > .table {
    margin-bottom: 0;
}

////////// Inline //////////

.table th.inline,
.table td.inline {
    line-height: 40px;
}

////////// Controls //////////

.table th.controls,
.table td.controls {
    padding-left: 10px;
    padding-right: 10px;
}

////////// Small //////////

.table small {
    color: $grey-500;
    font-weight: normal;
    display: block;
}

.table th.inline small,
.table td.inline small {
    display: inline;
}

////////// Hover / Rowlink / Bordered //////////

.table-hover {
    &.table-rowlink {
        // > tbody > tr > th {
        //     text-decoration: underline;
        // }

        > tbody > tr.hover > td > .badge,
        > tbody > tr.hover > th > .badge,
        > tbody > tr.focus > td > .badge,
        > tbody > tr.focus > th > .badge {
            border-color: $link-hover-color;
        }

        > tbody > tr:hover {
            cursor: pointer;
        }

        > tbody > tr:hover,
        > tbody > tr:focus {
            > th {
                color: $link-hover-color;
            }
        }
    }
}

.table-bordered {
    &.table-rowlink {
        border-radius: $border-radius-base;
        display: block;

        > thead > tr > th,
        > thead > tr > td,
        > tbody > tr > th,
        > tbody > tr > td,
        > tfoot > tr > th,
        > tfoot > tr > td {
            border-left-width: 0;
            border-right-width: 0;
        }

        > tbody > tr:last-child > th,
        > tbody > tr:last-child > td {
            border-bottom-width: 0;
        }
    }
}

////////// Striped //////////

.table-striped > thead > tr > th,
.table-striped > thead > tr > td,
.table-striped > tbody > tr > th,
.table-striped > tbody > tr > td,
.table-striped > tfoot > tr > th,
.table-striped > tfoot > tr > td {
    border-top: none;
}

.table-striped > tfoot > tr:first-child > th,
.table-striped > tfoot > tr:first-child > td {
    border-top: 2px solid $table-border-color;
}

////////// Icons / Images //////////

.table > thead > tr > th.table-icon,
.table > tbody > tr > td.table-icon {
    padding-right: 0;
}

.table td.inline i.flag-icon {
    margin-right: 10px;
}

////////// Prices //////////

.table > thead > tr > th.price,
.table > tbody > tr > td.price,
.table > tfoot > tr > th.price {
    width: 80px;
    padding-left: 0;

    text-align: right;
}

.table > tbody > tr > td.price .data-type {
    margin-top: 1px;
    border-bottom: 1px solid $grey-200;

    font-size: 10px;
    line-height: 13px;
    vertical-align: top;

    display: inline-block;
}

////////// Cell wrapper //////////

.cell-wrapper {
    width: 100%; height: 100%;
    min-height: 100%;

    display: block;
    position: relative;
}
