////////////////////////////////////////
// OS icons
////////////////////////////////////////

@font-face {
    font-family: 'OperatingSystem';
    src: url($fonts-path + 'os/os.eot?if1yjl');
    src: url($fonts-path + 'os/os.eot?if1yjl#iefix') format('embedded-opentype'),
         url($fonts-path + 'os/os.ttf?if1yjl') format('truetype'),
         url($fonts-path + 'os/os.woff?if1yjl') format('woff'),
         url($fonts-path + 'os/os.svg?if1yjl#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.os {
    display: inline-block;
    font: normal normal normal 14px/1 OperatingSystem;
    font-size: inherit;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
}

.os-fedora:before {
  content: "\e900";
}
.os-ubuntu:before {
  content: "\e901";
}
.os-coreos:before {
  content: "\e902";
}
.os-centos:before {
  content: "\e903";
}
.os-debian:before {
  content: "\e904";
}
.os-windows:before {
  content: "\e905";
}
.os-arch:before {
  content: "\e906";
}
.os-freebsd:before {
  content: "\e907";
}
.os-opensuse:before {
  content: "\e908";
}
