//////////////////////////////
// Variables
//////////////////////////////

@import "colors";

// Paths

$cdn-path:   "https://cdn01.o.auroraobjects.eu/foundation/" !default;
$fonts-path: $cdn-path + "fonts/" !default;
$img-path:   $cdn-path + "img/" !default;

// Font Awesome

$fa-version: "5.0.13" !default;

$fa-font-path:      $fonts-path + "font-awesome-5/" + $fa-version + "/webfonts" !default;
$fa-font-size-base: 16px !default;
$fa-css-prefix:     fa !default;
$fa-border-color:   #eee !default;
$fa-inverse:        #fff !default;
$fa-li-width:       2em !default;

// Colors

$brand-primary: $light-blue-500 !default;
$brand-default: $blue-grey-500 !default;
$brand-success: $green-500 !default;
$brand-info:    $light-blue-500 !default;
$brand-warning: $amber-500 !default;
$brand-danger:  $red-500 !default;

// Form states and alerts

$state-default-text:   $grey-500 !default;
$state-default-bg:     $grey-200 !default;
$state-default-border: $grey-500 !default;

$state-success-text:   $green-500 !default;
$state-success-bg:     $green-200 !default;
$state-success-border: $green-500 !default;

$state-info-text:      $light-blue-500 !default;
$state-info-bg:        $light-blue-200 !default;
$state-info-border:    $light-blue-500 !default;

$state-warning-text:   $amber-500 !default;
$state-warning-bg:     $amber-200 !default;
$state-warning-border: $amber-500 !default;

$state-danger-text:    $red-500 !default;
$state-danger-bg:      $red-200 !default;
$state-danger-border:  $red-500 !default;

// Scaffolding

$body-bg:    $grey-100 !default;
$text-color: #222 !default;

$link-color:            $light-blue-500 !default;
$link-hover-color:      $light-blue-400 !default;
$link-hover-decoration: underline !default;

// Typography

$font-family-sans-serif: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-serif:      Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace:  Menlo, Monaco, Consolas, "Courier New", monospace !default;

$font-family-base: $font-family-sans-serif !default;
$font-size-base:   16px !default;
$font-size-large:  ceil(($font-size-base * 1.25)) !default;
$font-size-small:  ceil(($font-size-base * 0.85)) !default;
$font-size-extra-small:  ceil(($font-size-base * 0.75)) !default;

$font-size-h1:     floor(($font-size-base * 2.6)) !default;
$font-size-h2:     floor(($font-size-base * 2.15)) !default;
$font-size-h3:     ceil(($font-size-base * 1.7)) !default;
$font-size-h4:     ceil(($font-size-base * 1.25)) !default;
$font-size-h5:     $font-size-base !default;
$font-size-h6:     ceil(($font-size-base * 0.85)) !default;

$line-height-base:     1.428571429 !default;
$line-height-computed: floor(($font-size-base * $line-height-base)) !default;

$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: normal !default;

// Components

$padding-base-vertical:     6px !default;
$padding-base-horizontal:   12px !default;

$padding-large-vertical:    10px !default;
$padding-large-horizontal:  16px !default;

$padding-small-vertical:    5px !default;
$padding-small-horizontal:  10px !default;

$padding-xs-vertical:       1px !default;
$padding-xs-horizontal:     5px !default;

$line-height-large:         1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5 !default;

$border-radius-base:        4px !default;
$border-radius-large:       6px !default;
$border-radius-small:       3px !default;

$component-active-color:    $white !default;
$component-active-bg:       $brand-primary !default;

$caret-width-base:          4px !default;
$caret-width-large:         5px !default;

// Tables

$table-cell-padding:           10px 20px !default;
$table-condensed-cell-padding: 5px 10px !default;

$table-bg:        transparent !default;
$table-bg-accent: $grey-50 !default;
$table-bg-hover:  $grey-100 !default;
$table-bg-active: $table-bg-hover !default;

$table-border-color: $grey-200 !default;

// Buttons

$btn-font-weight: normal !default;

$btn-default-color:  $text-color !default;
$btn-default-bg:     $white !default;
$btn-default-border: $grey-200 !default;

$btn-primary-color:  $white !default;
$btn-primary-bg:     $green-500 !default;
$btn-primary-border: $green-700 !default;

$btn-success-color:  $white !default;
$btn-success-bg:     $green-500 !default;
$btn-success-border: $green-700 !default;

$btn-info-color:  $white !default;
$btn-info-bg:     $light-blue-500 !default;
$btn-info-border: $light-blue-700 !default;

$btn-warning-color:  $white !default;
$btn-warning-bg:     $amber-500 !default;
$btn-warning-border: $amber-700 !default;

$btn-danger-color:  $white !default;
$btn-danger-bg:     $red-500 !default;
$btn-danger-border: $red-700 !default;

$btn-disabled-color:  $text-color !default;
$btn-disabled-bg:     $grey-100 !default;
$btn-disabled-border: $grey-500 !default;

$btn-contrast-color:  $light-blue-500 !default;
$btn-contrast-bg:     $white !default;
$btn-contrast-border: $grey-200 !default;

$btn-inverse-color:  $white !default;
$btn-inverse-bg:     $blue-grey-700 !default;
$btn-inverse-border: $blue-grey-900 !default;

$btn-link-disabled-color: $grey-500 !default;

// Forms

$input-bg:                       $white !default;
$input-bg-disabled:              $grey-200 !default;
$input-color:                    $text-color !default;
$input-border:                   $grey-400 !default;
$input-border-radius:            $border-radius-base !default;
$input-border-radius-large:      $border-radius-large !default;
$input-border-radius-small:      $border-radius-small !default;
$input-border-focus:             $light-blue-500 !default;
$input-color-placeholder:        $text-color !default;

$input-height-base:              ($line-height-computed + ($padding-base-vertical * 2) + 12) !default;
$input-height-large:             (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 12) !default;
$input-height-small:             (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 12) !default;

$input-group-addon-bg:           $white !default;
$input-group-addon-border-color: $input-border !default;

$input-group-addon-float-min-width: 40px;

$input-checkbox-switch-width:  48px !default;
$input-checkbox-switch-height: 26px !default;

$input-checkbox-switch-padding-width: 20px;

// Dropdowns

$dropdown-bg:              $white !default;
$dropdown-border:          rgba(0,0,0,.15) !default;
$dropdown-fallback-border: $grey-800 !default;
$dropdown-divider-bg:      $grey-200 !default;

$dropdown-link-color:       $text-color !default;
$dropdown-link-hover-color: $text-color !default;
$dropdown-link-hover-bg:    $grey-200 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg:    $component-active-bg !default;

$dropdown-link-disabled-color: $grey-500 !default;
$dropdown-header-color:        $grey-500 !default;

// Dropdown arrow (custom)

$dropdown-arrow-width: 6px !default;
$dropdown-arrow-color: $dropdown-bg !default;

$dropdown-arrow-outer-width:          ($dropdown-arrow-width + 1) !default;
$dropdown-arrow-outer-color:          $dropdown-border !default;
$dropdown-arrow-outer-fallback-color: $dropdown-fallback-border !default;

// Media queries breakpoints

$screen-xs-min: 480px !default;
$screen-sm-min: 768px !default;
$screen-md-min: 992px !default;
$screen-lg-min: 1200px !default;
$screen-xl-min: 1400px !default;

$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xl-min - 1) !default;

// Grid system

$grid-columns:              12 !default;
$grid-gutter-width:         40px !default;
$grid-float-breakpoint:     $screen-lg-min !default;
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;

// Container sizes

$container-sm: (720px + $grid-gutter-width) !default;
$container-md: (940px + $grid-gutter-width) !default;
$container-lg: (1140px + $grid-gutter-width) !default;
$container-xl: (1330px + $grid-gutter-width) !default;

// Utilities

$spacer:   20px !default;
$spacer-x: $spacer !default;
$spacer-y: $spacer !default;

// Navs

$nav-tabs-border-color:                       transparent !default;

$nav-tabs-link-hover-border-color:            $grey-200 !default;

$nav-tabs-active-link-hover-bg:               transparent !default;
$nav-tabs-active-link-hover-color:            $light-blue-500 !default;
$nav-tabs-active-link-hover-border-color:     $light-blue-500 !default;

$nav-tabs-justified-link-border-color:        $light-blue-500 !default;
$nav-tabs-justified-active-link-border-color: $grey-200 !default;

// Navbar

$navbar-height:        60px !default;
$navbar-border-radius: 0 !default;
$navbar-border-radius: 0 !default;

$navbar-default-color:  $text-color !default;
$navbar-default-bg:     $white !default;
$navbar-default-border: $grey-200 !default;

// Navbar links
$navbar-default-link-color:          $text-color !default;
$navbar-default-link-hover-color:    $text-color !default;
$navbar-default-link-hover-bg:       transparent !default;
$navbar-default-link-active-color:   $text-color !default;
$navbar-default-link-active-bg:      transparent !default;
$navbar-default-link-disabled-color: $grey-200 !default;
$navbar-default-link-disabled-bg:    transparent !default;

// Navbar toggle
$navbar-default-toggle-hover-bg:     $grey-200 !default;
$navbar-default-toggle-icon-bar-bg:  $text-color !default;
$navbar-default-toggle-border-color: transparent !default;

// Inverted
$navbar-inverse-color:  $white !default;
$navbar-inverse-bg:     $blue-grey-900 !default;
$navbar-inverse-border: $blue-grey-900 !default;

// Inverted navbar links
$navbar-inverse-link-color:          $white !default;
$navbar-inverse-link-hover-color:    $white !default;
$navbar-inverse-link-hover-bg:       transparent !default;
$navbar-inverse-link-active-color:   $white !default;
$navbar-inverse-link-active-bg:      transparent !default;
$navbar-inverse-link-disabled-color: $grey-200 !default;
$navbar-inverse-link-disabled-bg:    transparent !default;

// Navbar brand (custom)

$navbar-brand-width:      50px !default;
$navbar-brand-full-width: 250px !default;

// Popovers

$popover-bg:                    $white !default;
$popover-max-width:             276px !default;
$popover-border-color:          rgba(0,0,0,.15) !default;
$popover-fallback-border-color: $grey-800 !default;
$popover-title-bg:              $white !default;

$popover-arrow-width: 6px !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-width:          ($popover-arrow-width + 1) !default;
$popover-arrow-outer-color:          $popover-border-color !default;
$popover-arrow-outer-fallback-color: $popover-fallback-border-color !default;

// Alerts

$alert-padding:          15px ($grid-gutter-width / 2) !default;
$alert-border-radius:    $border-radius-base !default;
$alert-link-font-weight: bold !default;

$alert-success-bg:     $blue-grey-900 !default;
$alert-success-text:   $white !default;
$alert-success-border: transparent !default;
$alert-success-icon:   $green-500 !default;

$alert-info-bg:        $blue-grey-900 !default;
$alert-info-text:      $white !default;
$alert-info-border:    transparent !default;
$alert-info-icon:      $light-blue-500 !default;

$alert-warning-bg:     $blue-grey-900 !default;
$alert-warning-text:   $white !default;
$alert-warning-border: transparent !default;
$alert-warning-icon:   $amber-500 !default;

$alert-danger-bg:      $blue-grey-900 !default;
$alert-danger-text:    $white !default;
$alert-danger-border:  transparent !default;
$alert-danger-icon:    $red-500 !default;

// Alert bars

$alert-bar-success-bg:     $green-500 !default;
$alert-bar-success-text:   $white !default;
$alert-bar-success-border: $green-700 !default;

$alert-bar-info-bg:        $light-blue-500 !default;
$alert-bar-info-text:      $white !default;
$alert-bar-info-border:    $light-blue-700 !default;

$alert-bar-warning-bg:     $amber-500 !default;
$alert-bar-warning-text:   $white !default;
$alert-bar-warning-border: $amber-700 !default;

$alert-bar-danger-bg:      $red-500 !default;
$alert-bar-danger-text:    $white !default;
$alert-bar-danger-border:  $red-700 !default;

// Progress bars

$progress-bg:            $grey-200 !default;
$progress-bar-color:     $white !default;
$progress-border-radius: $border-radius-base !default;

$progress-bar-bg:         $brand-primary !default;
$progress-bar-success-bg: $brand-success !default;
$progress-bar-warning-bg: $brand-warning !default;
$progress-bar-danger-bg:  $brand-danger !default;
$progress-bar-info-bg:    $brand-info !default;

// Pagination

$pagination-color:           $text-color !default;
$pagination-bg:              transparent !default;
$pagination-border:          transparent !default;

$pagination-hover-color:     $text-color !default;
$pagination-hover-bg:        $grey-200 !default;
$pagination-hover-border:    transparent !default;

$pagination-active-color:    #fff !default;
$pagination-active-bg:       $brand-primary !default;
$pagination-active-border:   transparent !default;

$pagination-disabled-color:  $grey-500 !default;
$pagination-disabled-bg:     transparent !default;
$pagination-disabled-border: transparent !default;

// List groups

$list-group-item-padding:       10px 20px !default;

$list-group-bg:                 $white !default;
$list-group-border:             $grey-200 !default;
$list-group-border-radius:      $border-radius-base !default;

$list-group-hover-bg:           #f5f5f5 !default;
$list-group-active-color:       $component-active-color !default;
$list-group-active-bg:          $component-active-bg !default;
$list-group-active-border:      $list-group-active-bg !default;
$list-group-active-text-color:  lighten($list-group-active-bg, 40%) !default;

$list-group-disabled-color:      $grey-500 !default;
$list-group-disabled-bg:         $grey-100 !default;
$list-group-disabled-text-color: $list-group-disabled-color !default;

$list-group-link-color:         $link-color !default;
$list-group-link-hover-color:   $link-hover-color !default;
$list-group-link-heading-color: #333 !default;

$list-group-stack-margin: 5px !default;

// Panels

$panel-bg:              $white !default;
$panel-body-padding:    ($grid-gutter-width / 2) !default;

$panel-heading-v-padding: 15px !default;
$panel-heading-h-padding: ($grid-gutter-width / 2) !default;
$panel-heading-padding:   $panel-heading-v-padding $panel-heading-h-padding !default;

$panel-footer-padding:  $panel-heading-padding !default;
$panel-border-radius:   $border-radius-small !default;

$panel-inner-border: $grey-200 !default;
$panel-footer-bg:    $grey-50 !default;

$panel-default-text:       #333 !default;
$panel-default-border:     $grey-200 !default;
$panel-default-heading-bg: $white !default;

$panel-primary-text:       #333 !default;
$panel-primary-border:     $light-blue-500 !default;
$panel-primary-heading-bg: $light-blue-500 !default;

$panel-success-text:       #333 !default;
$panel-success-border:     $light-green-500 !default;
$panel-success-heading-bg: $light-green-500 !default;

$panel-info-text:       #333 !default;
$panel-info-border:     $light-blue-500 !default;
$panel-info-heading-bg: $light-blue-500 !default;

$panel-warning-text:       #333 !default;
$panel-warning-border:     $amber-500 !default;
$panel-warning-heading-bg: $amber-500 !default;

$panel-danger-text:       #333 !default;
$panel-danger-border:     $red-500 !default;
$panel-danger-heading-bg: $red-500 !default;

$panel-image-height: 200px;
$panel-image-height-sm: 100px;

$tile-hover-padding: 10px;
$tile-height: 200px;

// Close

$close-font-weight: normal !default;
$close-color:       $text-color !default;
$close-text-shadow: 0 1px 0 #fff !default;

// Type

$text-muted: $grey-500 !default;

// Breadcrumbs

$breadcrumb-padding-vertical:   8px !default;
$breadcrumb-padding-horizontal: 15px !default;
$breadcrumb-bg:                 $grey-200 !default;
$breadcrumb-color:              $grey-500 !default;
$breadcrumb-active-color:       $grey-800 !default;
$breadcrumb-separator:          "/" !default;

// Labels

$label-default-bg: $brand-default !default;
$label-primary-bg: $brand-primary !default;
$label-success-bg: $brand-success !default;
$label-info-bg:    $brand-info !default;
$label-warning-bg: $brand-warning !default;
$label-danger-bg:  $brand-danger !default;

// Jumbotron

$jumbotron-bg: $grey-50 !default;

// Modals

$modal-inner-padding:  ($grid-gutter-width / 2) !default;
$modal-title-padding:  15px ($grid-gutter-width / 2) !default;
$modal-footer-padding: $modal-title-padding !default;

$modal-title-line-height: 34px !default;

$modal-content-bg:                    $white !default;
$modal-content-border-color:          transparent !default;
$modal-content-fallback-border-color: $grey-200 !default;

$modal-backdrop-bg:         $blue-grey-50 !default;
$modal-backdrop-opacity:    .5 !default;
$modal-header-border-color: $grey-200 !default;

$modal-footer-bg:    $grey-50 !default;
$modal-footer-border-color: $modal-header-border-color !default;

$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;

// Confirmation

$confirmation-height: 80px !default;
$confirmation-bg:     $white !default;

// Loadin spinner

$loading-spinner-width:       40px !default;
$loading-spinner-height:      $loading-spinner-width !default;
$loading-spinner-border-size: 4px !default;

$loading-spinner-primary-color:            $light-blue-500 !default;
$loading-spinner-secondary-color:          rgba(0, 0, 0, 0.1) !default;
$loading-spinner-fallback-secondary-color: $grey-200 !default;

// Progress radial

$progress-radial-circle-size:       20px !default;
$progress-radial-circle-background: rgba(0, 0, 0, 0.1) !default;
$progress-radial-circle-color:      $light-blue-500 !default;

$progress-radial-inset-size:  14px !default;
$progress-radial-inset-color: $white !default;

$progress-radial-transition-length: 3s !default;

// Heroes

$hero-bg-default:    $grey-500 !default;
$hero-color-default: $white !default;

$hero-height-sm: 250px !default;
$hero-height-lg: 400px !default;

// Heroicons

$heroicons-accent-color:  $white !default;
$heroicons-fill-color:    $grey-100 !default;
$heroicons-shadow-color:  transparent !default;
$heroicons-outline-color: url(#gradient-linear) !default;

// Actionbar

$actionbar-bg: $white !default;

$nav-actionbar-link-padding:            18px 20px !default;
$nav-actionbar-link-color:              $text-color !default;
$nav-actionbar-link-hover-bg:           $grey-100 !default;
$nav-actionbar-active-link-hover-color: $white !default;
$nav-actionbar-active-link-hover-bg:    $light-blue-500 !default;

// Breakout

$breakout-padding:     ($grid-gutter-width * 1.5) 0 !default;
$breakout-bg:          $white !default;
$breakout-inverse-bg:  $blue-grey-800 !default;

// Sidebar

$sidebar-width: 250px !default;

$sidebar-default-bg: $navbar-default-bg !default;
$sidebar-default-border: $navbar-default-border !default;
$sidebar-default-contrast: $grey-100 !default;


// Footer

$footer-height: 500px !default;

// We need to import this last to override the default values.
@import "bootstrap-sass/assets/stylesheets/bootstrap/variables";
