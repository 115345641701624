//////////////////////////////
// Divider
//////////////////////////////

.divider-add,
.divider-config,
.divider-up {
    line-height: 1px;
    overflow: visible;

    &:after {
        @include fa-icon();

        padding: 0 0.5em;
        line-height: 1px;

        color: $light-blue-500;
        background-color: $white;

        display: inline;
    }
}

.divider-add:after {
    content: "\f055";
}

.divider-config:after {
    content: "\f013";
}

.divider-up:after {
    content: "\f01b";
}
