////////////////////////////////////////
// Utilities display
////////////////////////////////////////

////////// Display //////////

.d-block {
    display: block;
}

.d-iblock {
    display: inline-block;
}

.d-inline {
    display: inline;
}

.d-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

////////// Visibility //////////

.show-inline {
    display: inline !important;
}

.show-inline-block {
    display: inline-block !important;
}
