//////////////////////////////
// Breadcrumbs
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";

.breadcrumb {
    > li {
        + li:before {
            content: "\f105";
            font-family: FontAwesome;
            font-style: normal;
            font-size: 14px;

            -moz-osx-font-smoothing: grayscale;
             -webkit-font-smoothing: antialiased;
        }
    }
}
