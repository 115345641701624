//////////////////////////////
// Navs
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/navs";

.nav-tabs {
    border-bottom: 2px solid $nav-tabs-border-color;

    > li {
        margin-bottom: -2px;

        > a {
            @include link-variant($text-color, $light-blue-500, none);

            margin-right: 0;
            border: none;
            border-bottom: 2px solid transparent;

            font-size: $font-size-extra-small;
            font-weight: bold;
            text-transform: uppercase;

            border-radius: 0;

            &:hover {
                border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
            }
        }

        &.active > a {
            &,
            &:hover,
            &:focus {
                color: $text-color;

                border: none;
                border-bottom: 2px solid $nav-tabs-active-link-hover-border-color;

                cursor: default;
            }
        }
    }
}
