@font-face {
    font-family: 'TechniquesLogos';
    src: url($fonts-path + 'tech/tech.eot?4f8rub');
    src: url($fonts-path + 'tech/tech.eot?4f8rub#iefix') format('embedded-opentype'),
         url($fonts-path + 'tech/tech.ttf?4f8rub') format('truetype'),
         url($fonts-path + 'tech/tech.woff?4f8rub') format('woff'),
         url($fonts-path + 'tech/tech.svg?4f8rub#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.tech {
    display: inline-block;
    font: normal normal normal 14px/1 TechniquesLogos;
    font-size: inherit;
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
}

.tech-apache:before {
    content: "\e900";
}
.tech-docker:before {
    content: "\e901";
}
.tech-git:before {
    content: "\e902";
}
.tech-mariadb:before {
    content: "\e903";
}
.tech-maxscale:before {
    content: "\e904";
}
.tech-mongodb:before {
    content: "\e905";
}
.tech-nginx:before {
    content: "\e906";
}
.tech-postfix:before {
    content: "\e907";
}
.tech-varnish:before {
    content: "\e908";
}

