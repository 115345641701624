//////////////////////////////
// Panel titles
//////////////////////////////

.panel-title .user-image {
    margin-top: 5px;
    margin-right: 10px;

    display: block;
    float: left;
}

.panel-title {
    > .fa, > .far, > .fal, > .fas, > .fab {
        margin-right: 10px;
        text-align: center;

        font-size: 14px;
        line-height: 34px;
        vertical-align: middle;

        display: inline-block;
        float: left;
    }
}
