.container-auth {
    @media (min-width: $screen-sm-min) {
        width: 450px;
    }
}

.panel-auth {
    @include panel-variant($panel-default-border, $panel-default-text, $panel-default-heading-bg, $panel-default-border);
    border-color: $white;

    box-shadow: 0 10px 20px 0px rgba(0,0,0,0.15);
}

.auth-brand {
    width: $navbar-brand-full-width; height: $navbar-height;
    margin: 0; padding: 0;

    background-image: url($img-path + 'brand/brand-full-white.png');
    background-image: url($img-path + 'brand/brand-full-white.svg');
    background-color: transparent;
    background-size: 250px 50px;
    background-position: left center;
    background-repeat: no-repeat;

    text-indent: -9999px;
    overflow: hidden;
    z-index: 1050;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url($img-path + 'brand/brand-full-white@2x.png');
        background-image: url($img-path + 'brand/brand-full-white.svg');
    }
}

.auth-title {
    font-size: $font-size-base * 2;
    text-align: center;
}
