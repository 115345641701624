////////////////////////////////////////
// Dropdowns
////////////////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";

.dropdown-body {
    width: 100%;
    padding: 0 20px;
}

.dropdown-menu {
    padding: 10px 0;
    border-radius: $border-radius-small;

    -webkit-box-shadow: 0 2px 5px rgba(0,0,0,0.1);
       -moz-box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.dropdown-menu > li > a {
    padding: 10px 20px;
    line-height: 20px;
}

////////// Sizing //////////

.dropdown-menu-md {
    min-width: 260px;
}

////////// Danger item //////////

.dropdown-menu > li > a.danger,
.dropdown-menu > li > a.danger:hover,
.dropdown-menu > li > a.danger:focus {
    color: $red-500;
}

////////// Dropdown arrow //////////

.dropdown-menu:before,
.dropdown-menu:after {
    content: "";
    width: 0; height: 0;

    border-color: transparent;
    border-style: solid;
    border-width: $dropdown-arrow-outer-width;

    display: block;
    position: absolute;
}

// Top, left

.dropdown-menu:before,
.dropdown-menu:after {
    left: 20px;
    margin-left: -$dropdown-arrow-outer-width;
    border-top-width: 0;
    border-bottom-color: $dropdown-arrow-outer-fallback-color; // IE8 fallback
    border-bottom-color: $dropdown-arrow-outer-color;
    top: -$dropdown-arrow-outer-width;
}

.dropdown-menu:after {
    border-bottom-color: $dropdown-arrow-color;
    top: -$dropdown-arrow-outer-width + 1;
}

// Top, right

.dropdown-menu.dropdown-menu-right:before,
.dropdown-menu.dropdown-menu-right:after {
    margin-left: auto;
    margin-right: -$dropdown-arrow-outer-width;

    left: auto; right: 20px;
}

////////// Horizontal positioning //////////

.dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: 2px;
    left: auto;
    right: 100%;
}

.dropright .dropdown-menu {
    margin-top: 0;
    margin-left: 2px;
    left: 100%;
    right: auto;
}

////////// Vertical positioning //////////

.dropdown-menu-top {
    top: 0;
    bottom: auto;
}

.dropdown-menu-bottom {
    top: auto;
    bottom: 0;
}

////////// Dropdown icon variations //////////

.dropdown-icon-primary > a > i {
    color: $brand-primary;
}

.dropdown-icon-success > a > i {
    color: $brand-success;
}

.dropdown-icon-info > a > i {
    color: $brand-info;
}

.dropdown-icon-warning > a > i {
    color: $brand-warning;
}

.dropdown-icon-danger > a > i {
    color: $brand-danger;
}

////////// Fade //////////

.dropdown.fade {
    opacity: 1;
    -webkit-transition: none;
         -o-transition: none;
            transition: none;
}

.dropdown.fade .dropdown-menu {
    display: block;

    visibility: hidden;
    opacity: 0;
    transform: translate(0, -5%);

    transition: visibility 0s linear, opacity .15s linear, transform .3s ease-out;
}

.dropdown.fade.open .dropdown-menu {
    display: block;

    visibility: visible;
    opacity: 1;
    transform: translate(0, 0);
}

////////// Dropdown menu controls //////////

.dropdown-menu-controls:before,
.dropdown-menu-controls:after {
    display: none;
}

.dropleft > .dropdown-menu-controls {
    margin-right: 0;
    right: 0;
}

.dropdown-menu-controls {
    margin-bottom: 10px;

    &.dropdown-menu-right {
        margin-top: -40px;
        margin-right: 40px;

        > li > button {
            position: relative;
            z-index: $zindex-dropdown + 1; // Places the button above the pointer arrow.
        }

        &:before,
        &:after {
            margin-left: auto;
            margin-right: auto;
            margin-top: -$dropdown-arrow-outer-width;

            border-width: $dropdown-arrow-outer-width;
            border-right-width: 0;

            border-bottom-color: transparent;

            border-left-color: $dropdown-arrow-outer-fallback-color; // IE8 fallback
            border-left-color: $dropdown-arrow-outer-color;

            top: 20px;
            left: auto; right: -$dropdown-arrow-outer-width;

            display: block;
        }

        &:after {
            border-left-color: $dropdown-arrow-color;
            right: -$dropdown-arrow-outer-width + 1;
        }
    }
}

.dropdown-menu-controls > li > button {
    width: 100%;
    padding: 10px 20px;

    border: none;
    background-color: transparent;

    text-align: left;
    font-weight: normal;
    line-height: $line-height-base;
    color: $dropdown-link-color;

    display: block;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;

        outline: none;
    }
}

////////// Danger state //////////

.dropdown-menu-controls > li > button.danger {
    color: $red-500;
}

////////// Disabled state //////////

.dropdown-menu-controls > li > button:disabled {
    &,
    &:hover,
    &:focus {
        color: $dropdown-link-disabled-color;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background-color: transparent;
        background-image: none;
        @include reset-filter;
        cursor: $cursor-disabled;
    }
}

////////// Icon //////////

.dropdown-menu > li > a > i,
.dropdown-menu > li > button > i {
    width: (18em / 14);
    margin-right: 10px;
    text-align: center;
}

.dropdown-menu > li > a > .pull-right > i,
.dropdown-menu > li > button > .pull-right > i {
    width: (18em / 14);
    margin-left: 10px;
    text-align: center;
}

////////// Dropdown menu user //////////

.dropdown-menu-user {
    left: 20px !important; right: 20px !important;

    &::before,
    &::after {
        display: none;
    }

    @media (min-width: $grid-float-breakpoint) {
        left: auto !important;
    }
}
