//////////////////////////////
// Selects
//////////////////////////////

.selectboxit-container {
    position: relative;
    display: block;
    vertical-align: top;
}

.selectboxit-container * {
    user-select: none;
    outline: none;
    white-space: nowrap;
}

.selectboxit-container .selectboxit {
    width: 100% !important;
    margin: 0; padding: 0;

    border: 1px solid $input-border;

    overflow: hidden;
    display: block;
    position: relative;

    border-radius: 4px;
    cursor: pointer;
}

.selectboxit-container span,
.selectboxit-container .selectboxit-options a {
    max-width: 100% !important;

    height: 34px; /* Height of the drop down */
    line-height: 34px; /* Vertically positions the drop down text */
    display: block;

    > span {
        height: 32px;
        line-height: 32px;
    }
}

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
    outline: 0;
}

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled,
.selectboxit-options .selectboxit-disabled {
    opacity: 0.65;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: default;
}

/* Button Text */
.selectboxit-text {
    text-indent: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
}

.selectboxit .selectboxit-option-icon-container {
    margin-left: 5px;
}

/* Options List */
.selectboxit-container .selectboxit-options {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-width: 100%;  /* Minimum Width of the dropdown list box options */
    width: 100%;

    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: pointer;
    display: none;
    z-index: 999;
    border-radius: 4px;
    text-align: left;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/* Individual options */
 .selectboxit-option .selectboxit-option-anchor{
    padding: 0 2px;
}

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
    text-decoration: none;
}

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
    text-indent: 5px; /* Horizontal Positioning of the select box option text */
    margin: 0;
    list-style-type: none;
}

/* The first Drop Down option */
.selectboxit-option-first {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

/* The last Drop Down option */
.selectboxit-option-last {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
    font-weight: bold;
}

/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
    cursor: default;
}

/* Drop Down down arrow container */
.selectboxit-arrow-container {
    width: 30px;
    position: absolute;
    right: 0;

    .selectboxit-arrow {
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
}

/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
    top: 30%;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
    float: left;
}

.selectboxit-container .selectboxit-option-icon {
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
    width: 18px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    height: 100%;
    background-position: center;
    float: left;
}

.selectboxit-rendering {
    display: inline-block !important;
    zoom: 1 !important;
    visibility: visible !important;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
}
