//////////////////////////////
// List group stacks
//////////////////////////////

.list-group-stack {
    > .list-group-item {
        margin-bottom: $list-group-stack-margin;
        border-radius: $list-group-border-radius;
        border-width: 2px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
