////////////////////////////////////////
// Loading spinner
////////////////////////////////////////

.spinner {
    width: $loading-spinner-width; height: $loading-spinner-height;
    border: $loading-spinner-border-size solid $loading-spinner-fallback-secondary-color;
    border-color: $loading-spinner-secondary-color;
    border-radius: 50%;

    display: inline-block;
    position: relative;

    &,
    &::after {
        box-sizing: border-box;
    }

    &::after {
        content: "";
        width: $loading-spinner-width; height: $loading-spinner-height;
        border: $loading-spinner-border-size solid transparent;
        border-right-color: $loading-spinner-primary-color;
        border-radius: 50%;

        position: absolute;
        top: -$loading-spinner-border-size; left: -$loading-spinner-border-size;

        animation: spinner 1.6s infinite;
    }
}

@keyframes spinner {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
