// DEPRECATED

.panel-float {
    &.sticky {
        position: relative; // Reset affix effect on smaller screens.

        @media (max-width: $screen-md-max) {
            width: auto !important;

            position: relative !important;
            top: auto !important; bottom: auto !important;
            left: auto !important; right: auto !important;
        }
    }
}
