//////////////////////////////
// Panel generic
//////////////////////////////

.panel {
    position: relative;
    box-shadow: 0 1px 5px -1px rgba(0,0,0,0.4);
}

.panel-title {
    font-size: 12px;
    line-height: 34px;
    font-family: $font-family-sans-serif;
    font-weight: bold;
    text-transform: uppercase;

    display: block;

    small,
    .small {
        font-size: floor((100% * $font-size-small / $font-size-base));
        font-family: $font-family-sans-serif;
        font-weight: bold;

        color: $grey-500;

        display: block;
    }
}

.panel .panel-heading:last-child {
    // Removes the border from the heading when it's the last item in the panel.
    border-bottom-width: 0;
}

.panel-heading + .panel-footer {
    // Removes the border from the footer when it is directly after the heading.
    border-top-width: 0;
}
