//////////////////////////////
// Panel variants
//////////////////////////////

.panel-default {
    border-color: $white;
}

.panel-muted {
    background-color: $grey-50;
}
