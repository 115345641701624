html, body {
    height: 100%;
    min-height: 100%;
}

.webmail-bg {
    height: 500px;

    @include hero-gradient-variant($teal-300, $light-blue-300, $white)

    display: block;
    overflow: hidden;

    position: fixed;
    top: 0;
    left: 0; right: 0;

    z-index: -1;

    transform: skewY(-12deg);
    transform-origin: 0;
}

.client-icons {
    height: 20px;
    margin-bottom: 20px;

    @include clearfix;

    .icon {
        width: 40px; height: 40px;
        margin-top: -20px;
        margin-left: -20px;

        background-color: $grey-200;
        background-size: 45px 24px;
        background-position: center;
        background-repeat: no-repeat;

        display: block;
        float: right;

        border-radius: 25px;
        box-shadow: 0 3px 10px rgba(0,0,0,0.15);

        &.active {
            width: 50px; height: 50px;
            margin-top: -25px;
            margin-left: 0;
            background-color: $white;

            float: left;
        }
    }

    .icon.open_xchange {
        background-image: url(/img/providers/open_xchange.png);
    }

    .icon.roundcube {
        background-image: url(/img/providers/roundcube.png);
    }

    .icon.squirrelmail {
        background-image: url(/img/providers/squirrelmail.png);
    }
}
