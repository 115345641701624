//////////////////////////////
// Form rows
//////////////////////////////

.form-row {
    margin: 0; padding: $panel-body-padding 0;
    border-bottom: 1px solid $panel-inner-border;

    position: relative;

    box-sizing: border-box;

    &.collapse {
        display: none;

        &.in {
            display: block;
        }
    }

    + .form-row {
        margin-top: -1px;
        border-top: 1px solid $panel-inner-border;
    }
}

.form-heading {
    width: 100%;
    margin-bottom: $panel-body-padding;

    border-bottom: none;

    font-size: 16px;
    line-height: 34px;

    display: block;
    float: left;

    box-sizing: content-box;

    > small {
        color: $text-muted;
    }

    > .fa, > .far, > .fal, > .fas, > .fab {
        width: 1.28571429em;
        text-align: center;

        margin-right: 10px;
        text-align: center;
        font-size: 14px;
        line-height: 34px;
        vertical-align: middle;
        display: inline-block;
        float: left;
    }
}

.form-body {
    width: 100%;
    padding: 0;
    float: left;
    clear: both;
    @include clearfix;
}

////////// States //////////

.form-row {
    &.form-row-warning,
    &.form-row-danger {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        z-index: 1; // Push stated form-rows up. The form-row following the stated would otherwise overlap the bottom border.
    }

    &.form-row-warning {
        border-top-color: $amber-200;
        border-bottom-color: $amber-200;

        background-color: $amber-50;

        > .form-heading {
            border-bottom-color: $amber-200;
        }
    }

    &.form-row-danger {
        border-top-color: $red-200;
        border-bottom-color: $red-200;

        background-color: $red-50;

        > .form-heading {
            border-bottom-color: $red-200;
        }
    }
}

////////// Panel //////////

.panel-body {
    > .form-row:first-child {
        margin-top: -21px;
    }

    > .form-row:last-child {
        margin-bottom: -21px;
    }
}
