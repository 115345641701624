//////////////////////////////
// Lists
//////////////////////////////

$inner-border-radius: ($input-border-radius - 1);

.list-select {
    margin-bottom: $form-group-margin-bottom;
    list-style-type: none;

    &:before,
    &:after {
        display: table;
        content: "";
    }

    &:after {
        clear: both;
    }

    > li {
        margin: 0; padding: 0;
        margin-right: 5px;
        float: left;
    }

    .list-item {
        margin: 0; padding: 0;

        border: 1px solid $input-border;
        background-color: $input-bg;
        font-weight: normal;
        text-align: center;
        // overflow: hidden;

        display: block;
        border-radius: $input-border-radius;
        cursor: pointer;

        &.hover,
        &.focus {
            border-color: $light-blue-500;
            color: $light-blue-500;

            .list-footer {
                .text-muted {
                    color: $light-blue-500;
                }
            }
        }

        &.checked,
        &.checked.hover,
        &.checked.focus {
            border-color: $light-blue-700;
            background-color: $light-blue-500;
            color: $white;

            .list-heading {
                > .list-title {
                    &.fixed-icon {
                        > i {
                            background-color: $light-blue-700;
                        }
                    }
                }

                + .list-body {
                    border-color: $light-blue-700;
                }
            }

            .list-footer {
                border-color: transparent;
                background-color: $light-blue-700;
                color: $white;

                .text-muted {
                    color: $light-blue-900;
                }
            }

            .price {
                .data-type {
                    border-color: $white;
                }
            }
        }

        &.disabled {
            background-color: $grey-100;
            cursor: default;
        }
    }

    ////////////////////

    &.row {
        margin: 0 -10px; padding: 0;
        margin-top: -5px;

        @media (min-width: $screen-sm-min) {
            padding: 0 10px;
            margin-left: -20px;
            margin-right: -20px;
        }

        > li {
            padding: 0 10px;
            margin-top: 5px;
            margin-right: 0;
        }
    }

    &.list-justified {
        width: 100%;

        > li {
            margin-right: 0;
            margin-bottom: 5px;
            float: none;

            @media (min-width: $screen-sm-min) {
                margin-bottom: 0;
                display: table-cell;
                width: 1%;
            }
        }
    }

    ////////////////////

    .price {
        .data-type {
            margin-top: 1px;
            border-bottom: 1px solid #e4e1e1;

            font-size: 10px;
            line-height: 13px;
            vertical-align: top;

            display: inline-block;
        }
    }

    input[type='radio'],
    .custom-radio {
        display: none;
    }
}

////////////////////

.list-heading {
    display: block;

    + .list-body {
        border-top: 1px solid $panel-inner-border;
    }
}

.list-title {
    margin-top: 0;
    margin-bottom: 0;
    padding: 15px 20px;

    font-size: $font-size-base;
    color: inherit;

    > small {
        display: block;
    }

    > .fa,
    > .os {
        margin-bottom: 10px;
        font-size: 32px;
        display: block;
    }

    &.fixed-icon {
        padding-left: 50px;
        position: relative;

        > i {
            width: 40px; height: 100%;

            background-color: $grey-200;

            line-height: 45px;
            text-align: center;

            position: absolute;
            top: 0; bottom: 0;
            left: 0;
        }
    }
}

////////////////////

.list-body {
    padding: 15px 20px;
}

////////////////////

.list-footer {
    min-height: 48px; // Fixes some redrawing issues with Firefox when the footer
                      // is loaded a-sync.

    padding: 15px 20px;
    border-top: 1px solid $panel-inner-border;
    border-radius: 0 0 3px 3px;

    .fa.warning {
        color: $amber-500;
    }
}

////////////////////

.list-btn-group {
    border-radius: 4px;

    .btn-list {
        @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
        @include box-shadow(none);

        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;

        border: none;
        display: block;
        position: relative;
        border-radius: 0;

        &,
        &:hover,
        &:focus,
        &:active {
            border-top: 1px solid $panel-inner-border;
        }

        &.disabled,
        &[disabled] {
            cursor: $cursor-disabled;
            color: $input-color-placeholder;

            @include opacity(1);
            @include box-shadow(none);

            pointer-events: none;
        }

        .caret {
            margin-top: -2px;
            display: block;
            position: absolute;
            top: 50%; right: 10px;
        }
    }

    .btn-disk {
        border-radius: 0 0 $inner-border-radius $inner-border-radius;
    }

    .dropdown-menu {
        min-width: 100%;
    }
}

//// Zones ////

.list-zone {
    .list-heading {
        height: auto;

        background-size: 650px 150px;
        background-position: center top;
        background-repeat: no-repeat;

        @media (min-width: $screen-sm-min) {
            height: 100px;
        }
    }

    .list-title {
        margin-top: 10px; margin-left: 15px;
        padding: 5px;

        background-color: $white;
        background-color: rgba(255, 255, 255, 0.4);

        border-radius: 2px;
        display: inline-block;
    }

    .checked .list-title {
        background-color: $light-blue-500;
        background-color: rgba(3, 169, 244, 0.4);
        border-radius: 2px;
    }

    ////////////////////

    &.list-zone-europe .list-heading {
        background-image: url(/packages/pcextreme/foundation/img/zones/dotted-europe.png);
        background-image: url(/packages/pcextreme/foundation/img/zones/dotted-europe.svg);
    }

    &.list-zone-europe .checked .list-heading {
        background-image: url(/packages/pcextreme/foundation/img/zones/dotted-europe_active.png);
        background-image: url(/packages/pcextreme/foundation/img/zones/dotted-europe_active.svg);
    }
}

//// List settings ////

.list-settings {
    margin-bottom: 20px;

    overflow-x: scroll;
    white-space: nowrap;

    .row {
        min-width: 500px;
        margin: 0; padding: 0;
        margin-top: -1px;
        margin-left: 0px;

        display: table;

        > span {
            width: 75%;
            padding: 8px 0;

            border-top: 1px solid $grey-200;
            border-bottom: 1px solid $grey-200;
            border-collapse: collapse;

            display: table-cell;

            &.heading {
                width: 25%;
                font-weight: 500;
            }
        }
    }
}
