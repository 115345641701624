//////////////////////////////
// Heroicons
//////////////////////////////

.heroicon- {
    &outline {
        fill: $heroicons-outline-color;
    }

    &component- {
        &fill {
            fill: $heroicons-fill-color;
        }
        &accent {
            fill: $heroicons-accent-color;
        }
    }

    &shadows {
        fill: $heroicons-shadow-color; opacity: .4;
    }
}

.panel-image-top,
.panel-image-bottom {
    > .heroicon {
        margin-top: -30px;
        margin-left: -30px;

        position: absolute;
        top: 50%; left: 50%;
    }
}
