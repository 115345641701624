//////////////////////////////
// Panel labels
//////////////////////////////

.panel-label {
    width: 90%; height: 30px;
    padding: 5px 10px;
    background-color: $grey-200;

    font-size: 12px;
    font-weight: bold;
    line-height: 20px;

    text-align: center;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    display: block;
    position: absolute;
    top: -15px; left: 5%;
    z-index: 1;

    border-radius: $border-radius-small;
}

.panel-label-info {
    background-color: $brand-info;
    color: $white;
}

.panel-label-warning {
    background-color: $brand-warning;
    color: $white;
}

.panel-label-success {
    background-color: $brand-success;
    color: $white;
}

.panel-label-danger {
    background-color: $brand-danger;
    color: $white;
}

// Sizing

.panel-label-lg {
    height: 50px;
    top: -25px;
}
