////////////////////////////////////////
// Vertical align
////////////////////////////////////////

.vertical-align {
    height: 100%;
    white-space: nowrap;
}

.vertical-align:before {
    content: "";
    height: 100%; min-height: 100%;
    margin-right: -0.25em;
    vertical-align: middle;
    display: inline-block;
}

.vertical-align-wrapper {
    white-space: normal;
    vertical-align: middle;
    display: inline-block;

    @include clearfix();
}
