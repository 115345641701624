//////////////////////////////
// Title bar
//////////////////////////////

.titlebar {
    margin-bottom: 20px;

    background-color: $light-blue-500;

    position: relative;
}

.container,
.container-fluid {
    > .titlebar {
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
    }
}

.titlebar {
    > .container,
    > .container-fluid {
        position: relative;
        z-index: 2;
    }
}

////////// Components //////////

.titlebar-heading {
    .page-title {
        margin: 20px 0; padding: 0;

        font-size: 24px;
        line-height: 30px;
        color: $white;

        float: left;

        > small {
            margin-top: 10px;
            display: block;
        }
    }
}

.titlebar-footer {
    padding: 20px 0;
    border-top: 1px solid $light-blue-700;

    > .row {
        > div {
            border-right: 1px solid $light-blue-700;

            &:last-child {
                border-right: none;
            }
        }
    }
}

////////// Breadcrumbs //////////

.titlebar .breadcrumb {
    margin: 0; padding: 0;
    list-style: none;
    background-color: transparent;

    @include clearfix();

    > li {
        line-height: 20px;
        display: block;
        float: left;

        + li:before {
            color: $light-blue-700;
            display: block;
            float: left;
        }

        > a,
        > span {
            font-family: $font-family-sans-serif;
            font-size: $font-size-small;
            font-weight: normal;
            color: $light-blue-100;

            display: block;
            float: left;

            > strong {
                font-weight: normal;
            }
        }
    }
}

////////// Action //////////

.titlebar-action {
    float: right;
}

.titlebar {
    .controls-toggle {
        color: $white;
        background-color: transparent;

        &:hover,
        &:focus {
            color: $link-hover-color;

        }
    }
}

.titlebar-heading {
    .btn {
        margin-top: 30px; margin-bottom: 0;
    }

    .controls-toggle {
        margin-top: 30px;
    }
}

////////// Stats //////////

.titlebar {
    .stats {
        position: relative;

        >  .toggle {
            margin-top: -20px;
            font-size: 12px;
            color: $light-blue-100;
            position: absolute;
            top: 50%;
            right: 0;
        }
    }

    .stats-title {
        margin: 0;
        font-family: $font-family-sans-serif;
        font-size: $font-size-small;
        font-weight: normal;
        color: $light-blue-100;
    }

    .stats-count {
        margin-bottom: 10px;
        font-size: $font-size-h4;
        color: $white;
        display: block;

        > small {
            font-size: $font-size-small;
            color: $light-blue-100;
        }
    }
}
