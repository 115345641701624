////////////////////////////////////////
// NG-Table
////////////////////////////////////////

.ng-table th {
    user-select: none;

    &.sortable {
        cursor: pointer;

        .sort-indicator {
            padding-right: 18px;
            position: relative;

            &:after,
            &:before {
                content: "";
                margin-top: -4px;
                border-width: 0 4px 4px;
                border-style: solid;
                border-color: $grey-200 transparent;

                position: absolute;
                top: 50%;
                right: 5px;

                visibility: visible;
                opacity: 1;
            }

            &:before {
                margin-top: 2px;
                border-top-width: 4px;
                border-bottom-width: 0px;
            }
        }

        &:hover,
        &:focus {
            color: $text-color;
        }

        &.sort-asc {
            .sort-indicator,
            .sort-indicator:hover {
                &:after {
                    border-bottom-color: $grey-500;
                }
            }
        }

        &.sort-desc {
            .sort-indicator,
            .sort-indicator:hover {
                &:before {
                    border-top-color: $grey-500;
                }
            }
        }
    }
}

.ng-table-pager {
    display: none;
}
