@import "bootstrap-sass/assets/stylesheets/bootstrap/popovers";

.popover {
    border: none;
    border-radius: $border-radius-base;

    -webkit-box-shadow: 0 10px 20px rgba(0,0,0,0.4);
       -moz-box-shadow: 0 10px 20px rgba(0,0,0,0.4);
            box-shadow: 0 10px 20px rgba(0,0,0,0.4);

    max-width: 400px;
    padding: 0;

    .popover-inner {
        .popover-title {
            background-color: $blue-grey-900;
            color: $white;
            padding: 20px;
            border-bottom: 1px solid #eee;
            border-radius: ($border-radius-base - 1) ($border-radius-base - 1) 0 0;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
        }
        .popover-content {
            padding: 20px;
        }
    }
}
