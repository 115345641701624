//////////////////////////////
// Error pages
//////////////////////////////

////////// Body //////////

.error-page {
    padding-top: 0;
}

////////// Container //////////

.container-error {
    height: 100%;
    min-height: 100%;
    padding: 20px;
    text-align: center;
}

////////// Contents //////////

.application-error {
    text-align: center;

    .fa, .far, .fal, .fas, .fab {
        color: $red-500;
    }

    .heading,
    blockquote {
        margin: 0; padding: 20px 0;
        border: none;
        font-size: $font-size-h3;
        font-weight: $headings-font-weight;
        line-height: $headings-line-height;
        display: block;
    }

    blockquote footer {
        padding: 0;
        color: $text-muted;
    }

    .message {
        padding: 0;
    }
}
