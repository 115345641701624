.callout {
    margin: 0; padding: 10px 20px;
    margin-bottom: 20px;
    border-left: 2px solid #eee;
}

.callout h4 {
    font-size: $font-size-base;
    margin-top: 0;
    margin-bottom: 5px;
}

.callout p:last-child {
    margin-bottom: 0;
}

.callout code {
    background-color: #fff;
    border-radius: 3px;
}

/****/

.callout-danger {
    // background-color: #fdf7f7;
    border-color: $red-500;
}

.callout-danger h4 {
    color: $red-500;
}

.callout-warning {
    // background-color: #fcf8f2;
    border-color: $amber-500;
}

.callout-warning h4 {
    color: $amber-500;
}

.callout-info {
    // background-color: #f4f8fa;
    border-color: $light-blue-500;
}

.callout-info h4 {
    color: $light-blue-500;
}
