////////////////////////////////////////
// Avatars
////////////////////////////////////////

.avatar {
    width: 40px; height: 40px;
    margin: 0; padding: 0;

    color: $white;
    background-color: $light-blue-500;

    display: block;
    position: relative;

    border-radius: 50%;

    > i {
        width: 20px; height: 20px;
        margin: 0; padding: 0;
        margin-top: -10px;
        margin-left: -10px;

        font-size: 14px;
        line-height: 20px;
        text-align: center;

        display: block;
        position: absolute;
        top: 50%; left: 50%;
        z-index: 9;
    }

    > img {
        width: 40px; height: 40px;
        margin: 0; padding: 0;

        display: inline-block;;
        position: relative;;
        border-radius: 50%;

        z-index: 10;
    }
}

.avatar-xs {
    width: 20px !important; height: 20px !important;

    > i {
        font-size: 10px;
    }

    > img {
        width: 20px !important; height: 20px !important;
    }
}

.avatar-sm {
    width: 40px !important; height: 40px !important;

    > img {
        width: 40px !important; height: 40px !important;
    }
}

.avatar-md {
    width: 60px !important; height: 60px !important;

    > img {
        width: 60px !important; height: 60px !important;
    }
}

.avatar-lg {
    width: 80px !important; height: 80px !important;

    > img {
        width: 80px !important; height: 80px !important;
    }
}

.avatar-stack {
    line-height: 1;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;

    @include clearfix();

    &:hover .avatar {
        margin-right: 3px;

        &:last-child {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .avatar {
        margin-right: -15px;

        background-color: $blue-grey-800;
        border-right: 1px solid $white;

        text-align: center;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        z-index: 3;

        box-sizing: content-box;

        -webkit-transition: margin 0.1s ease-in-out;
                transition: margin 0.1s ease-in-out;

        > span {
            font-size: 10px;
            line-height: 20px;
        }

        &:first-child {
            z-index: 4;
        }

        &:last-child,
        &:nth-child(3) {
            margin-right: 0;
            border-right: 0;
            z-index: 2;
        }

        &:nth-child(4) {
            border-right-width: 0;
            margin-right: 0;
            z-index: 1;
        }
    }
}

// Variations

.avatar-default {
    color: $text-color;
    background-color: $grey-200;
}

.avatar-brand {
    background-color: $light-blue-500;
}

// States

.avatar-success {
    background-color: $green-500;
}

.avatar-info {
    background-color: $light-blue-500;
}

.avatar-warning {
    background-color: $amber-500;
}

.avatar-danger {
    background-color: $red-500;
}

.avatar-inverse {
    background-color: $blue-grey-500;
}
