//////////////////////////////
// Title icons
//////////////////////////////

@mixin title-icon-variant($primary-color) {
    .panel-title,
    .form-heading {
        > .fa, > .far, > .fal, > .fas, > .fab {
            color: $primary-color;
        }
    }
}
