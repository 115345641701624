//////////////////////////////
// NavBar Toggle
//////////////////////////////

.navbar-toggle {
    width: $navbar-height; height: $navbar-height + 1px;
    margin: 0; padding: 0;
    margin-bottom: -1px;

    border-width: 0;
    border-radius: 0;

    background: $white;

    position: relative;
    float: left;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }

    &.collapsed {
        background: transparent;
    }

    .icon-bar {
        width: 14.1421px; // Diagonal 10x10
        height: 2px;
        margin: 0;

        display: block;
        position: absolute;
        left: 25px;

        border-radius: none;

        -webkit-transform-origin: left center;
           -moz-transform-origin: left center;
             -o-transform-origin: left center;
                transform-origin: left center;

        -webkit-transition: .25s ease-in-out, background-color 0s;
           -moz-transition: .25s ease-in-out, background-color 0s;
             -o-transition: .25s ease-in-out, background-color 0s;
                transition: .25s ease-in-out, background-color 0s;

        &:nth-child(1) {
            top: 24px;

            -webkit-transform: rotate(45deg);
               -moz-transform: rotate(45deg);
                 -o-transform: rotate(45deg);
                    transform: rotate(45deg);
        }

        &:nth-child(2) {
            width: 0%;
            opacity: 0;

            top: 29px;
        }

        &:nth-child(3) {
            top: 34px;

            -webkit-transform: rotate(-45deg);
               -moz-transform: rotate(-45deg);
                 -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
        }

        + .icon-bar {
            margin-top: 0;
        }
    }

    &.collapsed .icon-bar {
        width: 20px;
        left: 20px;

        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);

        &:nth-child(2) {
            width: 20px;
            opacity: 1;
        }
    }
}

.navbar-default {
    .navbar-toggle {
        &:hover,
        &:focus {
            background-color: $white;
        }

        &.collapsed {
            &:hover,
            &:focus {
              background-color: $navbar-default-toggle-hover-bg;
            }
        }
    }
}

// User toggle

.navbar-toggle-user {
    width: 80px;
    padding: 10px 20px;
}
