//////////////////////////////
// Labels
//////////////////////////////

@mixin label-variant($color) {
    color: $color;

    &[href] {
        &:hover,
        &:focus {
            color: darken($color, 10%);
        }
    }
}
