//////////////////////////////
// Breakout
//////////////////////////////

.breakout {
    padding: $breakout-padding;
    background: $breakout-bg;

    position: relative;

    @include clearfix;

    .breakout-content {
        color: inherit;
    }
}

.breakout-inverse {
    background: $breakout-inverse-bg;
}

.breakout-transparent {
    background: none;
}
