////////////////////////////////////////
// Utilities spacing
////////////////////////////////////////

// Margin

.m-a-0, .m-0 { margin:  0 !important; }
.m-t-0 { margin-top:    0 !important; }
.m-r-0 { margin-right:  0 !important; }
.m-b-0 { margin-bottom: 0 !important; }
.m-l-0 { margin-left:   0 !important; }
.m-x-0 { margin-right:  0 !important; margin-left:   0 !important; }
.m-y-0 { margin-top:    0 !important; margin-bottom: 0 !important; }

.m-a, .m { margin:    $spacer !important; }
.m-t { margin-top:    $spacer-y !important; }
.m-r { margin-right:  $spacer-x !important; }
.m-b { margin-bottom: $spacer-y !important; }
.m-l { margin-left:   $spacer-x !important; }
.m-x { margin-right:  $spacer-x !important; margin-left: $spacer-x !important; }
.m-y { margin-top:    $spacer-y !important; margin-bottom: $spacer-y !important; }
.m-x-auto { margin-right: auto !important; margin-left: auto !important; }

.m-a-xs, .m-xs { margin: ($spacer / 2) !important; }
.m-t-xs { margin-top:    ($spacer-y / 2) !important; }
.m-r-xs { margin-right:  ($spacer-y / 2) !important; }
.m-b-xs { margin-bottom: ($spacer-y / 2) !important; }
.m-l-xs { margin-left:   ($spacer-y / 2) !important; }
.m-x-xs { margin-right:  ($spacer-x / 2) !important; margin-left:   ($spacer-x / 2) !important; }
.m-y-xs { margin-top:    ($spacer-y / 2) !important; margin-bottom: ($spacer-y / 2) !important; }

.m-a-md, .m-md { margin: ($spacer * 2) !important; }
.m-t-md { margin-top:    ($spacer-y * 2) !important; }
.m-r-md { margin-right:  ($spacer-y * 2) !important; }
.m-b-md { margin-bottom: ($spacer-y * 2) !important; }
.m-l-md { margin-left:   ($spacer-y * 2) !important; }
.m-x-md { margin-right:  ($spacer-x * 2) !important; margin-left:   ($spacer-x * 2) !important; }
.m-y-md { margin-top:    ($spacer-y * 2) !important; margin-bottom: ($spacer-y * 2) !important; }

.m-a-lg, .m-lg { margin: ($spacer * 4) !important; }
.m-t-lg { margin-top:    ($spacer-y * 4) !important; }
.m-r-lg { margin-right:  ($spacer-y * 4) !important; }
.m-b-lg { margin-bottom: ($spacer-y * 4) !important; }
.m-l-lg { margin-left:   ($spacer-y * 4) !important; }
.m-x-lg { margin-right:  ($spacer-x * 4) !important; margin-left:   ($spacer-x * 4) !important; }
.m-y-lg { margin-top:    ($spacer-y * 4) !important; margin-bottom: ($spacer-y * 4) !important; }

// Margin negative

.mn-a, .mn { margin:   -$spacer !important; }
.mn-t { margin-top:    -$spacer-y !important; }
.mn-r { margin-right:  -$spacer-x !important; }
.mn-b { margin-bottom: -$spacer-y !important; }
.mn-l { margin-left:   -$spacer-x !important; }
.mn-x { margin-right:  -$spacer-x !important; margin-left: -$spacer-x !important; }
.mn-y { margin-top:    -$spacer-y !important; margin-bottom: -$spacer-y !important; }

.mn-a-xs, .mn-xs { margin: (-$spacer / 2) !important; }
.mn-t-xs { margin-top:     (-$spacer-y / 2) !important; }
.mn-r-xs { margin-right:   (-$spacer-y / 2) !important; }
.mn-b-xs { margin-bottom:  (-$spacer-y / 2) !important; }
.mn-l-xs { margin-left:    (-$spacer-y / 2) !important; }
.mn-x-xs { margin-right:   (-$spacer-x / 2) !important; margin-left:   (-$spacer-x / 2) !important; }
.mn-y-xs { margin-top:     (-$spacer-y / 2) !important; margin-bottom: (-$spacer-y / 2) !important; }

.mn-a-md, .mn-md { margin: (-$spacer * 2) !important; }
.mn-t-md { margin-top:     (-$spacer-y * 2) !important; }
.mn-r-md { margin-right:   (-$spacer-y * 2) !important; }
.mn-b-md { margin-bottom:  (-$spacer-y * 2) !important; }
.mn-l-md { margin-left:    (-$spacer-y * 2) !important; }
.mn-x-md { margin-right:   (-$spacer-x * 2) !important; margin-left:   (-$spacer-x * 2) !important; }
.mn-y-md { margin-top:     (-$spacer-y * 2) !important; margin-bottom: (-$spacer-y * 2) !important; }

.mn-a-lg, .mn-lg { margin: (-$spacer * 4) !important; }
.mn-t-lg { margin-top:     (-$spacer-y * 4) !important; }
.mn-r-lg { margin-right:   (-$spacer-y * 4) !important; }
.mn-b-lg { margin-bottom:  (-$spacer-y * 4) !important; }
.mn-l-lg { margin-left:    (-$spacer-y * 4) !important; }
.mn-x-lg { margin-right:   (-$spacer-x * 4) !important; margin-left:   (-$spacer-x * 4) !important; }
.mn-y-lg { margin-top:     (-$spacer-y * 4) !important; margin-bottom: (-$spacer-y * 4) !important; }

.mn-a-xl, .mn-xl { margin: (-$spacer * 6) !important; }
.mn-t-xl { margin-top:     (-$spacer-y * 6) !important; }
.mn-r-xl { margin-right:   (-$spacer-y * 6) !important; }
.mn-b-xl { margin-bottom:  (-$spacer-y * 6) !important; }
.mn-l-xl { margin-left:    (-$spacer-y * 6) !important; }
.mn-x-xl { margin-right:   (-$spacer-x * 6) !important; margin-left:   (-$spacer-x * 6) !important; }
.mn-y-xl { margin-top:     (-$spacer-y * 6) !important; margin-bottom: (-$spacer-y * 6) !important; }

.mn-a-xxl, .mn-xxl { margin: (-$spacer * 8) !important; }
.mn-t-xxl { margin-top:      (-$spacer-y * 8) !important; }
.mn-r-xxl { margin-right:    (-$spacer-y * 8) !important; }
.mn-b-xxl { margin-bottom:   (-$spacer-y * 8) !important; }
.mn-l-xxl { margin-left:     (-$spacer-y * 8) !important; }
.mn-x-xxl { margin-right:    (-$spacer-x * 8) !important; margin-left:   (-$spacer-x * 8) !important; }
.mn-y-xxl { margin-top:      (-$spacer-y * 8) !important; margin-bottom: (-$spacer-y * 8) !important; }

// Padding

.p-a-0, .p-0 { padding:  0 !important; }
.p-t-0 { padding-top:    0 !important; }
.p-r-0 { padding-right:  0 !important; }
.p-b-0 { padding-bottom: 0 !important; }
.p-l-0 { padding-left:   0 !important; }
.p-x-0 { padding-right:  0 !important; padding-left:   0 !important; }
.p-y-0 { padding-top:    0 !important; padding-bottom: 0 !important; }

.p-a, .p { padding:    $spacer !important; }
.p-t { padding-top:    $spacer-y !important; }
.p-r { padding-right:  $spacer-x !important; }
.p-b { padding-bottom: $spacer-y !important; }
.p-l { padding-left:   $spacer-x !important; }
.p-x { padding-right:  $spacer-x !important; padding-left:   $spacer-x !important; }
.p-y { padding-top:    $spacer-y !important; padding-bottom: $spacer-y !important; }

.p-a-xs, .p-xs { padding: ($spacer / 2) !important; }
.p-t-xs { padding-top:    ($spacer-y / 2) !important; }
.p-r-xs { padding-right:  ($spacer-y / 2) !important; }
.p-b-xs { padding-bottom: ($spacer-y / 2) !important; }
.p-l-xs { padding-left:   ($spacer-y / 2) !important; }
.p-x-xs { padding-right:  ($spacer-x / 2) !important; padding-left:   ($spacer-x / 2) !important; }
.p-y-xs { padding-top:    ($spacer-y / 2) !important; padding-bottom: ($spacer-y / 2) !important; }

.p-a-md, .p-md { padding: ($spacer * 2) !important; }
.p-t-md { padding-top:    ($spacer-y * 2) !important; }
.p-r-md { padding-right:  ($spacer-y * 2) !important; }
.p-b-md { padding-bottom: ($spacer-y * 2) !important; }
.p-l-md { padding-left:   ($spacer-y * 2) !important; }
.p-x-md { padding-right:  ($spacer-x * 2) !important; padding-left:   ($spacer-x * 2) !important; }
.p-y-md { padding-top:    ($spacer-y * 2) !important; padding-bottom: ($spacer-y * 2) !important; }

.p-a-lg, .p-lg { padding: ($spacer * 4) !important; }
.p-t-lg { padding-top:    ($spacer-y * 4) !important; }
.p-r-lg { padding-right:  ($spacer-y * 4) !important; }
.p-b-lg { padding-bottom: ($spacer-y * 4) !important; }
.p-l-lg { padding-left:   ($spacer-y * 4) !important; }
.p-x-lg { padding-right:  ($spacer-x * 4) !important; padding-left:   ($spacer-x * 4) !important; }
.p-y-lg { padding-top:    ($spacer-y * 4) !important; padding-bottom: ($spacer-y * 4) !important; }

.p-a-xl, .p-xl { padding: ($spacer * 6) !important; }
.p-t-xl { padding-top:    ($spacer-y * 6) !important; }
.p-r-xl { padding-right:  ($spacer-y * 6) !important; }
.p-b-xl { padding-bottom: ($spacer-y * 6) !important; }
.p-l-xl { padding-left:   ($spacer-y * 6) !important; }
.p-x-xl { padding-right:  ($spacer-x * 6) !important; padding-left:   ($spacer-x * 6) !important; }
.p-y-xl { padding-top:    ($spacer-y * 6) !important; padding-bottom: ($spacer-y * 6) !important; }

.p-a-xxl, .p-xxl { padding: ($spacer * 8) !important; }
.p-t-xxl { padding-top:    ($spacer-y * 8) !important; }
.p-r-xxl { padding-right:  ($spacer-y * 8) !important; }
.p-b-xxl { padding-bottom: ($spacer-y * 8) !important; }
.p-l-xxl { padding-left:   ($spacer-y * 8) !important; }
.p-x-xxl { padding-right:  ($spacer-x * 8) !important; padding-left:   ($spacer-x * 8) !important; }
.p-y-xxl { padding-top:    ($spacer-y * 8) !important; padding-bottom: ($spacer-y * 8) !important; }

// Positioning

.pos-f-t {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-navbar-fixed;
}
