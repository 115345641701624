////////////////////////////////////////
// Loading bar
////////////////////////////////////////

#loading-bar,
#loading-bar-spinner,
#loading-bar-background {
    pointer-events: none;
    -webkit-pointer-events: none;

    -webkit-transition: 350ms linear all;
       -moz-transition: 350ms linear all;
         -o-transition: 350ms linear all;
            transition: 350ms linear all;
}

#loading-bar.ng-enter,
#loading-bar.ng-leave.ng-leave-active,
#loading-bar-spinner.ng-enter,
#loading-bar-spinner.ng-leave.ng-leave-active,
#loading-bar-background.ng-enter,
#loading-bar-background.ng-leave.ng-leave-active {
    opacity: 0;
}

#loading-bar.ng-enter.ng-enter-active,
#loading-bar.ng-leave,
#loading-bar-spinner.ng-enter.ng-enter-active,
#loading-bar-spinner.ng-leave,
#loading-bar-background.ng-enter.ng-enter-active,
#loading-bar-background.ng-leave {
    opacity: 1;
}

#loading-bar .bar {
    width: 100%; height: 2px;
    background: $light-blue-500;
    border-bottom-right-radius: 1px;
    border-top-right-radius: 1px;

    z-index: $zindex-navbar-fixed + 1;

    position: fixed;
    top: 0; left: 0;

    -webkit-transition: width 350ms;
       -moz-transition: width 350ms;
         -o-transition: width 350ms;
            transition: width 350ms;
}

/* Fancy blur effect */
#loading-bar .peg {
    width: 70px; height: 2px;

    position: absolute;
    top: 0;
    right: 0;

    opacity: .45;

    -webkit-box-shadow: $light-blue-500 1px 0 6px 1px;
       -moz-box-shadow: $light-blue-500 1px 0 6px 1px;
        -ms-box-shadow: $light-blue-500 1px 0 6px 1px;
            box-shadow: $light-blue-500 1px 0 6px 1px;

    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
}

#loading-bar-spinner {
    display: block;

    z-index: 10002;

    position: fixed;
    top: 10px;
    left: 10px;
}

#loading-bar-spinner .spinner-icon {
    width: 14px; height: 14px;

    border:  solid 2px transparent;
    border-top-color:  $light-blue-500;
    border-left-color: $light-blue-500;
    border-radius: 50%;

    -webkit-animation: loading-bar-spinner 400ms linear infinite;
       -moz-animation: loading-bar-spinner 400ms linear infinite;
        -ms-animation: loading-bar-spinner 400ms linear infinite;
         -o-animation: loading-bar-spinner 400ms linear infinite;
            animation: loading-bar-spinner 400ms linear infinite;
}

@-webkit-keyframes loading-bar-spinner {
    0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes loading-bar-spinner {
    0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
    100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes loading-bar-spinner {
    0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
    100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes loading-bar-spinner {
    0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
    100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes loading-bar-spinner {
    0%   { transform: rotate(0deg);   transform: rotate(0deg); }
    100% { transform: rotate(360deg); transform: rotate(360deg); }
}

#loading-bar-background {
    width: 100%; height: 100%;
    background: rgba(0, 0, 0, 0.7);

    z-index: $zindex-navbar;

    display: block;
    position: fixed;
    top: $navbar-height;  left: 0;
}
