//////////////////////////////
// Forms - Checkbox
//////////////////////////////

// Switch button
.checkbox-switch {
    label {
        padding-left: $input-checkbox-switch-width + $input-checkbox-switch-padding-width;
        display: block
    }

    input[type='checkbox'][name] {
        // Apply the same vertical spacing as the button, but take the
        // default 4px margin into account.
        margin-top: -(floor($input-checkbox-switch-height / 2) - 4px);

        // Adjust margin to accomodate the extra padding we apply
        // for the switch, but nudge the checkbox just a tiny bit to the right.
        margin-left: -($input-checkbox-switch-width + ($input-checkbox-switch-padding-width / 2));

        position: absolute;
        top: 50%;
    }
}

.checkbox-switch-button {
    width: $input-checkbox-switch-width;
    height: $input-checkbox-switch-height;

    margin: 0; padding: 0;
    margin-top: -(floor($input-checkbox-switch-height / 2));

    position: absolute;
    top: 50%;
    left: 0;

    border-radius: 15px;
    border: 0;
    background: $grey-300;

    transition: background 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: inset 0 2px 4px -3px rgba(0,0,0,0.4);
    outline: none;

    &:before {
        content: '';
        width: $input-checkbox-switch-height;
        height: $input-checkbox-switch-height;

        background: white;
        border: 1px solid #c1c1c1;
        border-radius: 15px;

        display: block;
        box-shadow: 0 1px 2px -1px rgba(0,0,0,0.4);

        position: absolute;
        top: 0px;
        left: -1px;

        transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
}

.checkbox-switch.in {
    .checkbox-switch-button {
        background: $light-blue-500;

        &:before {
            left: floor($input-checkbox-switch-width - $input-checkbox-switch-height);
            border-color: $light-blue-500;
        }
    }
}

// Positioning
.checkbox-right {
    label {
        padding-left: 0;
        padding-right: 20px;
        position: relative;
    }

    input[type='checkbox'][name] {
        margin-left: 0;

        position: absolute;
        right: 0;
    }
}

.checkbox-switch.checkbox-right {
    label {
        padding-left: 0;
        padding-right: $input-checkbox-switch-width + 20px;
    }

    input[type='checkbox'][name] {
        margin-left: 0;
        margin-right: ($input-checkbox-switch-padding-width / 2);
    }

    .checkbox-switch-button {
        left: unset; right: 0;
    }
}
