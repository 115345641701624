////////////////////////////////////////
// Google - Places Autocomplete
////////////////////////////////////////

// Policy states the 'powered by' logo is required.
// @see: https://developers.google.com/places/web-service/policies
//
// But we are rebels, so fuck the policy.
.pac-logo:after {
    content: none;
}

.pac-container {
    min-width: 160px;
    margin: 2px 0 0; padding: 5px 0;

    font-size: $font-size-base;
    font-family: $font-family-sans-serif;

    text-align: left;
    background-color: $dropdown-bg;
    border: 1px solid $dropdown-fallback-border;
    border: 1px solid $dropdown-border;
    border-radius: $border-radius-base;
    background-clip: padding-box;

    position: absolute;
    z-index: $zindex-modal;

    @include box-shadow(0 6px 12px rgba(0,0,0,.175));

    .pac-item {
        padding: 3px 20px;
        font-weight: normal;

        border-top: none;

        font-size: inherit;
        line-height: $line-height-base;
        color: $dropdown-link-color;

        display: block;
        white-space: nowrap;
        clear: both;
    }
}

.pac-item {
    &:hover {
        cursor: pointer;
    }

    &:hover,
    &:focus,
    &.pac-item-selected {
        color: $dropdown-link-hover-color;
        background-color: $dropdown-link-hover-bg;
    }
}

.pac-item-query {
    font-size: inherit;

    .pac-matched {

    }
}

.pac-icon {
    width: (18em / 14); height: auto;
    text-align: center;
    margin-top: 0; margin-right: 10px;
    vertical-align: middle;

    &:before {
        @include fa-preset(900);

        content: fa-content($fa-var-map-marker);
    }
}

.pac-icon,
.hdpi .pac-icon {
    background: none;
}
