//////////////////////////////
// Jumbotron
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";

.jumbotron {
    .container &,
    .container-fluid & {
        border-radius: $border-radius-small;
    }
}
