//////////////////////////////
// Alerts
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/alerts";

.alert {
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}

////////// Progress radial //////////

.alert {
    > div {
        position: relative;
    }

    .progress-radial {
        margin-top: -($progress-radial-circle-size/2);
        position: absolute;
        top: 50%;
        right: -40px;

        .inset {
            background-color: $blue-grey-900;
        }
    }
}

.alert:hover {
    .progress-radial {
        display: none;
    }
}

////////// Close //////////

.alert-dismissible {
    .close {
        width: 40px; height: auto;
        margin: -1px 0;

        border-left: 1px solid $blue-grey-800;

        color: $white;
        text-shadow: none;
        opacity: 1;

        display: block;

        position: absolute;
        top: 0; bottom: 0;
        right: 0;

        border-radius: 0 3px 3px 0;

        &:hover,
        &:focus {
            color: $link-hover-color;
            opacity: 1;
            outline: none;
        }

        &:active {
            color: $link-color;
        }
    }
}

////////// Icons //////////

.alert:before {
    width: 40px; height: auto;
    margin: -1px; padding: 0;
    margin-right: 0;

    background-color: $blue-grey-900;

    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 900;

    font-size: 14px;

    text-align: center;
    line-height: 50px;
    color: $black;

    position: absolute;
    top: 0; bottom: 0;
    left: 0;

    border-radius: 3px 0 0 3px;

    -moz-osx-font-smoothing: grayscale;
     -webkit-font-smoothing: antialiased;
}

.alert.alert-success:before {
    content: "\f00c";
    color: $alert-success-icon;
}

.alert.alert-info:before {
    content: "\f129";
    color: $alert-info-icon;
}

.alert.alert-warning:before {
    content: "\f071";
    color: $alert-warning-icon;
}

.alert.alert-danger:before {
    content: "\f071";
    color: $alert-danger-icon;
}

////////// Bar //////////

.alert-bar {
    margin: 0;
    padding-top: 18px;
    padding-bottom: 18px;

    border-width: 0 0 1px 0;
    border-radius: 0;

    > a {
        @include link-variant($white, $white, underline);

        &,
        &:hover,
        &:focus {
            text-decoration: underline;
        }

        &:focus {
            text-decoration-style: dotted;
        }
    }

    text-align: center;
    z-index: $zindex-navbar;
}

.alert-bar.alert-success {
    @include alert-variant($alert-bar-success-bg, $alert-bar-success-border, $alert-bar-success-text);

    &:before {
        content: none;
    }
}

.alert-bar.alert-info {
    @include alert-variant($alert-bar-info-bg, $alert-bar-info-border, $alert-bar-info-text);

    &:before {
        content: none;
    }
}

.alert-bar.alert-warning {
    @include alert-variant($alert-bar-warning-bg, $alert-bar-warning-border, $alert-bar-warning-text);

    &:before {
        content: none;
    }
}

.alert-bar.alert-danger {
    @include alert-variant($alert-bar-danger-bg, $alert-bar-danger-border, $alert-bar-danger-text);

    &:before {
        content: none;
    }
}
