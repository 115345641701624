//////////////////////////////
// Hero
//////////////////////////////

.hero {
    padding: 22px 0;

    background-color: $hero-bg-default;
    background-image: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;

    color: $hero-color-default;

    position: relative;

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
}

.hero-contained {
    border-radius: $border-radius-large;
    box-shadow: 0 20px 30px -20px rgba(0, 0, 0, 0.4);
}

////////// Title //////////

// Comparable to .lead
.hero-title {
    margin-bottom: $line-height-computed;
    font-size: floor(($font-size-base * 1.15));
    font-weight: 300;
    line-height: 1.4;

    @media (min-width: $screen-sm-min) {
      font-size: ($font-size-base * 1.5);
    }
}

.hero-title-breadcrumb {
    font-weight: 500;

    > a {
        @include link-variant($blue-grey-500, $blue-grey-400, none);
        font-weight: 300;

        display: inline-block;
        transition: color .15s ease;

        &:after {
            content: "#{$breadcrumb-separator}";
            padding: 0 5px;
            color: $blue-grey-500;
        }
    }
}

// Panel

.panel-hero {
    background: rgba(255,255,255, 0.2);
    box-shadow: none;

    hr {
        border-color: $white;
        border-style: dashed;
    }
}

////////// Containers //////////

.hero {
    > .container,
    > .container-fluid {
        height: 100%;
    }
}

////////// Containers //////////

.hero {
    > .container,
    > .container-fluid {
        position: relative;
    }

    .btn-add {
        position: absolute;
        bottom: -42px; right: 20px;
    }

    // TODO: Find out what this part does and where it is used.
    > .dropdown {
        margin-top: -20px;
        position: absolute;
        top: 50%; right: 20px;
    }

    .controls-toggle {
        border-radius: 20px;
    }
}

////////// Actions //////////

.hero-actions {
    margin: 0;
    position: absolute;
    bottom: -42px; right: 20px;

    > li {
        vertical-align: middle;
    }

    .btn {
        width: 40px; height: 40px;
        padding: 0; margin: 0;

        font-size: 14px;
        line-height: 40px;

        border-radius: 20px;
        @include box-shadow(0 10px 10px -8px rgba(0,0,0,.5));

        // Adds support for notification badges.
        position: relative;

        > i {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

////////// Button //////////

.hero-origin {
    padding: 0 15px;
    margin-right: 15px;
    border-right: 1px solid $blue-grey-900;

    font-size: 14px;
    vertical-align: top;

    display: inline-block;
    float: left;

    line-height: floor(($font-size-base * 1.15) * 1.4);

    @media (min-width: $screen-sm-min) {
        line-height: floor(($font-size-base * 1.5) * 1.4);
    }

    @include link-variant($blue-grey-500, $blue-grey-400, none);
}

////////// Sizes //////////

.hero-sm {
    height: $hero-height-sm;
}

.hero-lg {
    height: $hero-height-lg;
}
