//////////////////////////////
// Actionbar
//////////////////////////////

@mixin fa-preset($weight: 400) {
    @include fa-icon();

    font-family: 'Font Awesome 5 Pro';
    font-weight: $weight;
}
