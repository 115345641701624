@font-face {
    font-family: 'Eagle';
    src:url($fonts-path + 'eagle/eagle.eot?18pxg1');
    src:url($fonts-path + 'eagle/eagle.eot?#iefix18pxg1') format('embedded-opentype'),
        url($fonts-path + 'eagle/eagle.woff?18pxg1') format('woff'),
        url($fonts-path + 'eagle/eagle.ttf?18pxg1') format('truetype'),
        url($fonts-path + 'eagle/eagle.svg?18pxg1#eagle') format('svg');
    font-weight: normal;
    font-style: normal;
}

.eagle {
    font-family: 'Eagle';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;

    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.eagle-dash:before {
    content: "\e600";
}

.eagle-media:before {
    content: "\e601";
}

.eagle-more:before {
    content: "\e602";
}

.eagle-page:before {
    content: "\e603";
}

.eagle-user:before {
    content: "\e604";
}
