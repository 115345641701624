////////////////////////////////////////
// Notifications
////////////////////////////////////////

#notifications {
    width: 100%;
    padding: 0 ($grid-gutter-width / 2);

    display: block;
    position: fixed;
    top: $navbar-height + 10;
    right: 0;

    z-index: $zindex-modal;

    @media (min-width: $grid-float-breakpoint) {
        width: 400px;
    }
}

////////// Alerts //////////

#notifications .alert {
    margin-bottom: 10px;
    box-shadow: 0 1px 5px -1px rgba(0,0,0,0.4);
}

////////// Badge //////////

.notification-badge {
    width: 8px; height: 8px;

    border: 1px solid $white;
    background-color: $red-500;

    display: inline-block;
    border-radius: 4px;
}

.btn > .notification-badge {
    position: absolute;
    top: 10px; right: 10px;
}
