//////////////////////////////
// Scaffolding
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
