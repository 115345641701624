//////////////////////////////
// Footer
//////////////////////////////

.footer-wrapper {
    min-height: 100%;
    margin-bottom: -$footer-height;
}

.footer-push {
    height: $footer-height;
}

.footer {
    min-height: $footer-height;
    background-color: $blue-grey-900;
    color: $white;

    font-family: $font-family-sans-serif;

    a,
    small,
    .small,
    small > a,
    .small > a {
        color: inherit;
    }
}

.footer-heading {
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative;

    &:after {
        content: "";
        width: 50%; height: 0;
        border-top: 1px solid #333e44;
        display: block;

        position: absolute;
        bottom: 0; left: 0;
    }
}

.footer-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: ceil(($font-size-base * 1.2));
    color: inherit;
}

.footer-list {
    padding-left: 0;
    list-style: none;

    > li {
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.footer-copy {
    border-top: 1px solid #333e44;
    font-size: $font-size-small;
    text-align: center;
}

.footer-language,
.footer-social {
    > a {
        margin-right: 10px;
    }
}

.footer-social {
    > a {
        text-decoration: none;
    }
}
