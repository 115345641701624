//////////////////////////////
// Navigation bar
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/navbar";

// Modules
@import "navbar/navbar-toggle";

// Unsorted

.navbar {
    -webkit-box-shadow: none;
       -moz-box-shadow: none;
            box-shadow: none;

    -webkit-transition: box-shadow 0.8s;
            transition: box-shadow 0.8s;

    &.affix {
        -webkit-box-shadow: 0 2px 2px 0px rgba(0,0,0,0.1);
           -moz-box-shadow: 0 2px 2px 0px rgba(0,0,0,0.1);
                box-shadow: 0 2px 2px 0px rgba(0,0,0,0.1);
    }
}

// TODO: Check if this doesn't break all the things.
.navbar-form.navbar-left {
    margin-left: -$navbar-padding-horizontal;
}

////////// Header //////////

.navbar-header {
    position: relative;

    @media (min-width: $grid-float-breakpoint) {
        border-right: 1px solid $navbar-default-border;

        .navbar > .container &,
        .navbar > .container-fluid & {
            margin-left: -$navbar-padding-horizontal;
        }
    }
}

.navbar-inverse {
    .navbar-header {
        @media (min-width: $grid-float-breakpoint) {
            border-right-color: $navbar-inverse-border;
        }
    }
}

////////// Brand //////////

.navbar-brand {
    width: $navbar-brand-width; height: $navbar-height;
    margin: 0; padding: 0;
    margin-left: -($navbar-brand-width / 2);

    background-image: url($img-path + 'brand/brand.png');
    background-image: url($img-path + 'brand/brand.svg');
    background-color: transparent;
    background-size: 50px 50px;
    background-position: center;
    background-repeat: no-repeat;

    text-indent: -9999px;
    overflow: hidden;

    position: absolute;
    left: 50%;
    float: none;
    z-index: 1050;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url($img-path + 'brand/brand@2x.png');
        background-image: url($img-path + 'brand/brand.svg');
    }

    @media (min-width: $grid-float-breakpoint) {
        width: $navbar-brand-full-width - 1; // -1 border size
        margin-left: 0;

        background-image: url($img-path + 'brand/brand-full.png');
        background-image: url($img-path + 'brand/brand-full.svg');
        background-size: 250px 50px;
        background-position: left center;

        position: relative;
        left: auto;
        float: left;

        .navbar > .container &,
        .navbar > .container-fluid & {
            margin-left: 0;
        }

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url($img-path + 'brand/brand-full@2x.png');
            background-image: url($img-path + 'brand/brand-full.svg');
        }
    }
}

.navbar-inverse {
    .navbar-brand {
        background-image: url($img-path + 'brand/brand-white.png');
        background-image: url($img-path + 'brand/brand-white.svg');

        @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            background-image: url($img-path + 'brand/brand-white@2x.png');
            background-image: url($img-path + 'brand/brand-white.svg');
        }

        @media (min-width: $grid-float-breakpoint) {
            background-image: url($img-path + 'brand/brand-full-white.png');
            background-image: url($img-path + 'brand/brand-full-white.svg');

            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
                background-image: url($img-path + 'brand/brand-full-white@2x.png');
                background-image: url($img-path + 'brand/brand-full-white.svg');
            }
        }
    }
}

// ////////// Nav //////////

.navbar-nav {
    > li > a {
        font-size: $font-size-extra-small;
        font-weight: bold;
        text-transform: uppercase;

        padding-left: 20px;
        padding-right: 20px;

        position: relative;

        &,
        &:hover,
        &:focus {
            color: $navbar-default-link-color;
        }

        &:after,
        &:hover:after,
        &:focus:after {
            content: "";
            width: 10px; height: 2px;
            margin-left: -5px;

            background: transparent;

            display: block;
            position: absolute;
            left: 50%; bottom: floor(($navbar-padding-vertical / 2)) + 1;

            @include transition(.1s ease-in-out width, .1s ease-in-out margin-left);
        }

        &:hover:after,
        &:focus:after {
            width: 20px;
            margin-left: -10px;
            background: $light-blue-500;
        }

        &.navbar-nav-user {
            padding: 10px 20px !important;

            &:hover,
            &:focus {
                > .avatar {
                    border: 2px solid $light-blue-500;
                    overflow: hidden;

                    > img {
                        margin: -2px 0 0 -2px;
                    }
                }
            }

            &:after,
            &:hover:after,
            &:focus:after {
                content: none;
                display: none;
            }
        }

        > .fa, > .far, > .fal, > .fas, > .fab {
            font-size: 14px;
        }
    }

    > .active > a {
        &:after,
        &:hover:after,
        &:focus:after {
            width: 20px;
            margin-left: -10px;
            background: $light-blue-500;
        }
    }
}

// Navbar page

.navbar-page {
    border: none;
    background-color: transparent;

    .navbar-brand {
        color: $navbar-inverse-brand-color;
        &:hover,
        &:focus {
            color: $navbar-inverse-brand-hover-color;
            background-color: $navbar-inverse-brand-hover-bg;
        }
    }

    .navbar-text {
        color: $navbar-default-color;
    }

    .navbar-form {
        margin-top: 0;
        margin-bottom: 0;
    }

    .navbar-nav {
        > li > a {
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        > .active > a {
            &,
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        > .disabled > a {
            &,
            &:hover,
            &:focus {
                background-color: transparent;
            }
        }
    }

    // Darken the responsive nav toggle
    .navbar-toggle {
        border-color: $navbar-default-toggle-border-color;
        &:hover,
        &:focus {
            background-color: $navbar-default-toggle-hover-bg;
        }
        .icon-bar {
            background-color: $navbar-default-toggle-icon-bar-bg;
        }
    }

    // TODO: Experimental
    > .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
}
