//////////////////////////////
// List group
//////////////////////////////

@import "bootstrap-sass/assets/stylesheets/bootstrap/list-group";

// Modules
@import "list-groups/list-group-stack";

.list-group-item {
    padding: $list-group-item-padding;
}

.list-group-item-link {
    color: $list-group-link-color;
    text-decoration: none;

    > small {
        color: $text-muted;
    }

    &:hover,
    &:focus {
        color: $light-blue-500;
        text-decoration: none;
        outline: none;
    }
}

.list-group-item-title,
.list-group-item-title label {
    font-weight: bold;
    display: block;

    > .avatar {
        margin-right: 20px;
    }

    small {
        color: $grey-500;
        font-weight: normal;
        display: block;
    }
}

.list-group-item-actions {
    position: absolute;
    top: 10px; right: 5px;

    > li {
        vertical-align: middle;
    }

    .avatar-stack,
    li > .fa, li > .far, li > .fal, li > .fas, li > .fab {
        margin: 10px 0;
    }

    li > .fa, li > .far, li > .fal, li > .fas, li > .fab {
        vertical-align: middle;
    }

    .btn-group {
        .btn {
            @include button-variant($text-color, $white, $grey-200);
            @include box-shadow(none);

            width: 40px; height: 40px;
            padding: 0;
            font-size: 14px;
            line-height: 40px;

            // Adds support for notification badges.
            position: relative;

            > i {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.list-group-hover {
    .list-group-item {
        &:hover,
        &:focus,
        &.in {
            background-color: $list-group-hover-bg;
            cursor: pointer;
            outline: none;

            .list-group-item-link {
                color: $list-group-link-hover-color;
                text-decoration: none;
            }
        }

        &.in {
            background-color: transparent;
        }

        &:active {
            .list-group-item-link {
                text-decoration: none;
            }
        }
    }
}
